
import TextAppear from "./components/textAppear";
import AnimGsap from "./components/animGsap";
import Anchors from "./components/anchors";
import ColorThemeDetector from "./components/colorThemeDetector";
// Blocks Animations HP 
import HeroAnimations from "./components/homepage/heroAnimations";
import TabModuleAnimations from "./components/homepage/tabModuleAnimations";
import OurWorkAnimations from "./components/homepage/ourWorkAnimations";
import ServicesAnimations from "./components/homepage/servicesAnimations";
import DareAnimations from "./components/homepage/dareAnimations";
//Animations globales
import RiseUp from "./components/riseup";
import RiseUpDecal from "./components/riseupDecal";
import Riselinear from "./components/riselinear";

// Composants 
import Counter from "./components/counter";
import Slider from "./components/slider";
import Accordeon from "./components/accordeon";
import ScrollTop from "./components/scrollTop";
import Modal from './components/modal';
import ReduceTitle from './components/reduceTitle';

import FindUs from "./components/findUs"
import Header from "./components/header"
import Menu from "./components/menu";
import Contact from "./components/contact";
import Trombi from "./components/trombi";
import Press from "./components/press";
import Prosumer from "./components/prosumerReports"
import Gallery from "./components/gallery";
import Video from "./components/video";

const main = {
    init: function () {
        // document.fonts.ready.then(function () {
        ReduceTitle.init();
        TextAppear.init();
        AnimGsap.init();
        Anchors.init();
        RiseUp.init();
        RiseUpDecal.init();
        Riselinear.init();
        HeroAnimations.init();
        TabModuleAnimations.init();
        OurWorkAnimations.init();
        ServicesAnimations.init();
        DareAnimations.init();
        FindUs.init();
        Counter.init();
        Slider.init();
        Accordeon.init();
        Header.init();
        Menu.init();
        Contact.init();
        ColorThemeDetector.init();
        Trombi.init();
        Press.init();
        Prosumer.init();
        Gallery.init();
        ScrollTop.init();
        Modal.init();
        Video.init();

        // })
    }
}

export default main