'use strict'

const ReduceTitle = {
    init: function () {
        this.resizedTitle = document.querySelector('.js-title-element:not(.full)');
        if (this.resizedTitle) {
            ReduceTitle.adjustFontSize();
            window.addEventListener('resize', () => this.adjustFontSize());
        }
    },
    adjustFontSize: function () {
        if (!this.resizedTitle && window.innerWidth < 991) return;

        const containerWidth = this.resizedTitle.offsetWidth;
        let textWidth = this.resizedTitle.querySelector('span').offsetWidth;

        let currentFontSize = parseFloat(window.getComputedStyle(this.resizedTitle, null).getPropertyValue('font-size'));

        // Calculez la proportion entre la largeur du conteneur et celle du texte
        let scale = containerWidth / textWidth;
        console.log(Math.max(currentFontSize * scale, 1))
        // Ajustez la taille de police pour correspondre à l'espace disponible
        this.resizedTitle.style.fontSize = `${Math.max(currentFontSize * scale, 1) < 56 ? Math.max(currentFontSize * scale, 1) : 56}px`;
    },
};

export default ReduceTitle;

